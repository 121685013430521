.olaMain{

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .editi{
    cursor: pointer;
  }
.selectMembership{
    width: 28%;
    margin-top: 2%;
    border-radius: 8px;
    border: 1px solid rgb(255, 87, 88);
    background: white;
    color: rgb(255, 87, 88);
    padding: 1px;
}
.olatext{
  display: flex;
  justify-content: end;
}
.ola{
  path {
    color: green;
  }

}
.listatBreak{
  @media only screen and (max-width: 800px) {
  
    display: flex;
    flex-direction: column;
    width: 121%;
    margin-left: -4%;
    font-size: 10px;
    }
}
.groupList{
 
  @media only screen and (max-width: 800px) {
    width: 104%;
  }

}
.workingHours{
  display: flex;
  justify-content: end;
  gap: 7px;
  margin-left: 21px;
}
.listGroup{
  width: 99%;
    margin-left: 0%;
}
.timeListGroup{
  display: flex;
  margin-left: -4.2%;
  margin-bottom: 7px;
  gap: 14px;

  @media only screen and (max-width: 800px) {
   
    display: flex;
    gap: 17px;
    margin-left: -10%;
    margin-bottom: 7px;
  }
}
.listText{
  text-align: center;
}


.olaFormControl{
  align-items: center;
  .forDisable[disabled]{
    background-color:rgb(245, 245, 245);
  }
  input:disabled{
    background-color:rgb(245, 245, 245);
  } 
  label{
    font-size: 12px;
    color: gray;
  }
}

.inputWorkingHours{
    border: none;
    padding: 2px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 1px #e1d9d9;
}

}
