.bgSalon {
    background-color: #eff2f5;
    .salon {
      background-color: #f7f9fa;
      padding: 90px;
      .salonName {
        font-size: 40px;
      }
      .rating-number {
        font-size: 20px;
        color: #ffae00;
        font-weight: bold;
      }
      .star {
        font-size: 35px;
        color: #ffae00;
        line-height: 8px;
      }
      .location svg {
        width: 50px;
        height: 20px;
        fill: red;
      }
  
      .time svg {
        width: 50px;
        height: 20px;
        fill: red;
      }
      // .slideShowSalon {
      //   hr {
      //     border: none;
      //     border-top: 3px solid #ff5758;
      //     margin: 20px 0;
      //   }
      // }
      .chooseBtn {
        background-color: #ff5758;
        color: white;
        padding: 1px;
        width: 120px;
        height: 30px;
      }
    }
    // .textCarousel{
    //   background-color: #fff;
    //   opacity: 0.3;
    //   color: black;
    //   padding: 10px;
  
    // }
    .browseServices{
      font-weight: bold;
     
    }
    .checkServices {
      background-color: #eff2f5;
      display: flex;
      margin-bottom: 14px;
      justify-content: center;
    }
    .ratingTheSalon{
      font-weight: bold;
      font-size: 13px;
    }
    .all,
    .hair {
      background-color: #ffffff;
      width: 150px;
      margin: 20px;
      padding: 18px;
      justify-content: center;
      border-radius: 13px;
      display: flex;
      align-items: center;
      height: auto;
      .allIcon {
        width: 40px;
        height: 60px;
        object-fit: cover;
      }
      .allText {
        text-align: center;
      }
      .hairIcon {
        width: 50px !important;
        padding: 0 !important;
      }
    }
    .review{
      font-weight: bold;
    }
    .numberOfRating{
      color: #ffae00;
      font-weight: bold;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    }
    .numerOfReview{
      color:#999da0;
      font-size: 12px;
    }
    .descriptionSalon{
      font-weight: bold;
      font-size: 13px;
    }
    .descTreatment{
      color: rgb(43, 39, 39);
      font-weight: 600;
    }
    .trajningBy,
    .timeOfPublishReview{
      color: #999da0;
      font-size: 12px;
    }
    .nameOfMember{
      font-size: 12px;
    }
    .servicesReview{
      background-color: #fff;
      border: 1px solid #d1d1d1;
      color: #ff5758;
      width: auto;
      padding: 5px;
      border-radius: 10px;
      font-size: 10px;
    }
    .filterOfCategory {
      background-color: #fff;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
    .verifiedReviews {
      background-color: #fff;
      margin: 10px;
      padding: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      .textVerified {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .team{
      font-weight: bold;
      margin-bottom: 10px;
    }
    .initials {
      width: 50px; 
      height: 50px; 
      border-radius: 50%; 
      background-color: #ff5758; 
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .serviceOfMember{
      background-color: #fff;
      width: 70px;
      padding: 6px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .aboutSalon{
      font-weight: bold;
    }
    .aboutSalonLocation {
      color: #080808;
      font-weight:400;
      font-family: Abadi Extra Light;
  
    }
    .aboutSalonName{
      font-weight:100;
    }
    
    .aboutInfo{
      // color: rgb(121, 117, 117);
      color: #a5a69f;
      font-size: 15px;
      font-family: Abadi Extra Light;
    }
    .day,
    .schedule{
      color: #565c5c;
      font-size: 15px;
      font-weight: 200;
      font-family: Abadi Extra Light;
    }
  
    .aboutLocation{
      background-color: #fff;
    }
    .mapAbout{
      height: 300px !important;
    }
    .green-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3f7652;
    margin-right: 10px;
  }
  .line{
    border: 2px solid #ff5758;
  }
  
    
  }
  @media (max-width:991px){
    .bgSalon{
      .salon{
        padding:10px;
      }
      .salonName{
        margin-top: 50px;
      }
    }
  
  @media (max-width:767px){
    .bgSalon {
      .salon {
      padding: 0;
      text-align: center;
      }
      .col-md-6{
      .descTreatment{
        justify-content: center !important;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .col-md-6 {
      justify-content: center !important;
      margin-bottom: 20px;
  
      .ratingTheSalon{
        font-size: 18px;
        margin: 0;
      }
    }
    .col-md-8 {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .col-md-2 {
        text-align: center;
      }
      .col-md-10 {
        text-align: center;
      }
      
    }
    .review{
      font-size: 20px;
      margin-bottom: 30px;
    }
    .headingServiceText h5{
      margin-bottom: 30px ;
      text-align: center;
      font-weight: bold;
    }
    .filterOfCategory{
      width: 100%;
    }
    .ratingClients{
      justify-content: center;
    }
    .descriptionSalon,
    .trajningBy{
      font-size: 15px;
    }
    .servicesReview{
      font-size: 16px;
    }
    .day {
      display: flex;
      align-items: center;
    }
  
    .day .schedule {
      margin-left: 10px;
    }
    .day, .schedule {
      width: 50%;
      padding: 0 8px;
    
  }
    
  }
  }
  
  
  }
  
  
  
.custom-file-upload{
  border: none;
}
input[type="file"]::file-selector-button {
  border: none;
  text-decoration: none;
  margin-left: 35px;
  font-size: large;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  background-color: #ff5758;
}

.iconUpload{
  width: 110px;
}
.iconUploadImg{
  display: flex;
  justify-content: center;
}

.chooseFile{
  display: flex;
    flex-direction: column;
    align-items: center;
    
}
// margin-left: 103px

.olaaaaaaaa{
  display: flex;
  @media only screen and (max-width: 800px) {

    display: flex;
    flex-direction: column;
  }
}
.olaa{
  @media only screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
  }
}


.mapEditSalon{
  width:'500px';
  @media only screen and (max-width: 800px) {
      width:'200px'
  }
}