body{
    background-color: #CCD6DD;
    overflow-x: hidden;
    color: #101212;
}
#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
@font-face {
    font-family: mySecondFont;
    src:url(../../../public/font/cooper/fonts/otf/Cooper-ExtraBold.otf);
  
  }
  @font-face {
    font-family: myThirdFont;
    src:url(../../../public/font/Quicksand/static/Quicksand-SemiBold.ttf);
  }
  @font-face {
    font-family: myFourthFont;
    src:url(../../../public/font/Quicksand/static/Quicksand-Light.ttf);
  }
.headerLayout{
    background-color: white;
    width: 100%;
    .headerLayoutDiv{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;
        .imageLayout{
            .logoImage{
                width: 160px
            }
        }
        .buttonsForLogin{
            display: flex;
            gap: 7px;
            .loginButton{
                border-radius: 5px;
                border: 1px solid #e4e2e2;
                background-color: white;
                padding: 3px 10px;
                font-size: 12px;
            }
            .registerButton{
                border: none;
                color: white;
                background-color: #FF6768;
                padding: 8px 25px ;
                border-radius: 3px;
                font-size: 12px;
            }
        }
    }
}
.sectionText{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
        .textBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .headingFirst{
            color: #101212;
            font-size: 64px;
            font-family: 'mySecondFont';
        }
        .paragraphFirst{
            font-family: myThirdFont;
            font-size: 30px;
        }
}
.buttonsForRegister{
    .registerButton{
        border: none;
        color: white;
        background-color: #FF6768;
        padding:8px 25px;
        border-radius: 3px;
        font-size: 12px;
    }
    }
}
    .photoWeb{
        width: 100%;
    }
.headingText{
    display: flex;
    justify-content: center;
    text-align: center;
    .headingThree{
        color: #101212;
        font-size: 36px;
        font-family: 'myThirdFont';
    }
}    
.textEmoji{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .emoji{
        display: flex;
        flex-direction: row;
        justify-content: center;
        .emojiCircle{
        display: flex;
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: #FBF9FF;
        text-align: center;
        justify-content: center;
        .emojiStyle{
            width: 50px;
        }
    }
}
}
.textOfIcon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2{
        color: #101212;
        font-family:mySecondFont;
        font-size: 36px;
    }
    p{
        color: #101212;
        // font-weight: 900;
    }
}

.emojiAndText{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.boxLayout{
    border: none;
    background-color: white;
    .textBoxLayout{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .textBoxLayoutHeading{
            font-size: 36px;
            color: #101212;
            font-family: mySecondFont;
            text-align: center;
        }
        .paragrafTextBoxLayout{
            text-align: center;
            // font-weight: 900;
        }
    }
    .boxImageTextLayout{
        .threeBox{
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .firstBox{
                display: flex;
                .emoji{
                    display: flex;
                    flex-direction: row;
                    justify-content: end;
                    .emojiCircle{
                        display: flex;
                        border-radius: 100px;
                        width: 70px;
                        height: 70px;
                        background-color: #FBF9FF;
                        text-align: center;
                        justify-content: center;
                        .emojiStyleIcon{
                            width: 30px;
                        }
                        .emojiStyleIcons{
                            width: 18px;
                        }
                    }
                }
                .textOfEmoji{
                    display: flex;
                    align-items: center;
                    text-align: start;
                    .paragrafiText{
                        // font-weight: 600;
                    }
                }
            }
        }
    }
}

.registerLayout{
    background-color: #FF6768;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 20px;
    .headingFirst{
        color: white;
        font-size: 50px;
        font-weight:800;
        font-family: 'mySecondFont';
    }
    .paragrafiLight{
        font-size: 22px;
        color: white;
        font-family: myFourthFont;
    }
    .inputButton{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid;
        width: 100%;
        background-color: white;
        border-radius: 30px;
        padding: 5px 5px 5px 20px;
        border: none;
        .inputText{
            background-color: transparent;
            border: none;
            width: 80%;
            &:focus{
                outline: none;
            }
            &::placeholder{
                font-size: 12px !important;
                color: #cac9c9 !important;
            }

        }
        .filloButton{
            border-radius: 25px;
            padding:5px  20px;
            border: none;
            background-color: #FF6768;
            color: white;
            font-size: 10px;
        }
    }
    .registerDiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }
}
.footerLayout{
    background-color: white;
    padding: 10px;
    width: 100%;
    margin-top: auto;
    .imageLayout{
        .logoImage{
            width: 140px;
        }
    }
    .iconsLogoss{
        display: flex;
        gap: 40px;
        .imageTerminet{
            display: flex;
            justify-content: center;
    .iconsLogo{
        display: flex;
        flex-direction: row;
        .icons{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .iconInsta{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 40px;
                height: 40px;
                background-color: #FBF9FF;
                border-radius: 50%;
            }
        }       
}
}
.logoHarrisia{
    .imageLayout{
               .logoImages{
                width: 65px;
               } 
            }
        }  
    }
}


// LOGINIIIIII
.loginLayout{
    .logoTermini{
        display: flex;
        justify-content: center;
    }
        .backgroundImage{
            background-size: 744px;
            background-image: url("../../assets/images/landingPage-Mockups/Login-removebg-preview.png");
            background-position: center;
            background-repeat: no-repeat;
            padding: 80px;
        .formLayout{
            padding: 80px;
            background-color: white;
            .rightText{
                display: flex;
                flex-direction: row;
                align-items: center;
                .headingText{
                    font-family: 'mySecondFont';
                }
            }
        }
    }
}
    
    //register
    .formRegisterLayout{
        padding: 1px;
        background-color: white;
        .rightText{
            display: flex;
            flex-direction: row;
            .divImageSignUp{
           display: flex;
           justify-content: end;
           align-items: end;
            .signUpImage{
                width: 151px;
                height: 247px;
                margin-left: -100px !important;
            }
        }
        .rightHeadingText{
           display: flex;
           flex-direction: column;
           gap: 20px;
           justify-content: space-evenly;
           align-items: center;
           padding: 0px 0px 0px 34px;
            .textParagraf{
               display: flex;
               flex-direction: column;
               gap: 30px;
               .headingText{
                font-family: 'mySecondFont';
                text-align: justify;
                font-size: xxx-large;
                }
            }
        }
    }
    .leftText{
        padding: 48px 48px 48px 48px;
    }
}
@media only screen and (max-width: 990px) {
        .headerLogin{
            display: none;
        }
.headerLayout{
    .headerLayoutDiv{
        .buttonsForLogin{
            .registerButton{
                display: none;
            }
        }
    }
}
.sectionText{
    padding: 30px;
    .textBox{
        .paragraphFirst {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 30px;
            line-height: normal;
        }
        .headingFirst {
            margin-bottom: 30px;
            color: #101212;
            font-size: 40px;
            font-weight: 800;
            font-family: mySecondFont;
        }
    }
} 
 
.headingText{
    display: flex;
    justify-content: center;
    text-align: center;
    .headingThree{
        font-size: 25px;
    }
}
.boxLayout{
    width: 90%;
    .boxImageTextLayout{
        display: flex;
        flex-direction: column-reverse;
        .threeBox{
            .firstBox{
                .emoji{
                    justify-content: center;
                }
                .textOfEmoji{
                 justify-content: center;
                    text-align: center;
                }
            }
        }
    }
    .textBoxLayout{
        .paragrafTextBoxLayout{
            br {
                display: none;
            }
        }
        .textBoxLayoutHeading{
            font-size: 25px;
        }
    }
}
.textOfIcon h4 {
    color: #101212;
    font-family: mySecondFont;
    font-size: 19px;
}
.footerLayout{
    .imageLayout{
        .logoImage{
            width: 140px;
        }
    }
    .iconsLogoss{
        gap: unset;
        .imageTerminet{
            text-align: center;
            .iconsLogo{
                justify-content: center;
                width: 100%;
                .icons{
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
}

.textEmoji{
    .emojiAndText{
        .emoji{
            .emojiCircle{
                width: 90px;
                height: 90px;
            }
        }
        .textOfIcon h2 {
            color: #101212;
            font-family: mySecondFont;
            font-size: 18px;
        }
    }
}


.formRegisterLayout{
    margin-bottom: 30px;
   .rightText{
    flex-direction: row;
    justify-content: flex-start;
    padding: 12px 32px;
    .rightHeadingText{
        padding: 0;
        .textParagraf{
            gap: unset;
               .headingText{
                    text-align: unset;
                    font-size:x-large;
                br{
                    display: none;
                }
            }
            .paragrafi{
                display: none;
            }
        }
    }
    .divImageSignUp{
        display: none;
    }
   } 
   .leftText {
      padding: 8px 31px 48px 31px;
  }
}
.loginLayout{
    .backgroundImage{
        padding: 20px;
        margin-top: 50px;
        background-size: 500px;
        .formLayout{
            margin-bottom: 40px;
            padding: 25px;
            .rightText{
                justify-content: center;
            }
        }
    }
}
}

