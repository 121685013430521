.multistep-form {
    width: 100%;
    max-width: 600px;
    max-height: 800px;
    overflow-x: auto;
    margin: 0 auto;
    padding: 20px;
    border: 4px solid #ccc;
    border-radius: 20px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    .step {

        &.active {
            display: block;
        }

        h2 {
            font-size: 1.5rem;
            margin-bottom: 10px;
        }

        label {
            display: block;
            font-weight: bold;
        }

        // input[type='text'],
        // input[type='email'],
        // input[type='password'],
        // input[type='tel'],
        // textarea,
        
        .Navbutton {
            margin-top: 10px;
            padding: 8px 16px;
            background-color:#ff5758;
            color: #fff;
            border: none;
            border-radius: 3px;
        }
        .serviceAddBtn{
            margin-top: 5px;
            margin-left: 15px;
            padding: 3px 10px 3px 10px;
            background-color:#ff5758;
            color: #fff;
            border: none;
            border-radius: 5px;
        }
        .AddWorker{
            background-color: green;
        }
    }

    @media screen and (max-width: 480px) {
        padding-left: 90px;
        max-height: 800px;
        overflow-x: scroll;
        .step {
            label,
            input,
            textarea,
            button {
                font-size: 14px;
            }
            button {
                padding: 6px 12px;
            }
        }
    }
}

//STEP HEADER
.step-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 18px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
        background-color: #ccc;
    }
    .step1 {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 1.2rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease-in-out;
    
        &.completed {
            background-color: lightcoral; 
            color: white;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        }
        &.active {
            background-color: white; 
            color: black;
            border: 2px solid lightcoral; 
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        }
        
    }
    @media screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    margin: 0 auto;
    padding: 20px 0 0 75px;
    font-size: 18px;
    }
}


